import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';

const VerticalText = ({ text = '' }) => {
  const characterArray = text.split('')
  return (
    <div style={{ flexDirection: 'column', display: 'flex' }}>
      { characterArray.map((character, index) => (
        <div key={index} style={{ flex: 1, width: 35, alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          <h1 style={{ fontSize: 50, color: 'rgba(235,25,25,1)' }}>{ character }</h1>
        </div>
      )) }
    </div>
  )
}

const MODES = [
  'SKILLS',
  'CARDSBELOW',
  'OCCULT',
  'CONTACT'
]

function App() {
  const [ mode, setMode ] = useState(MODES[3])
  const [ scrollPosition, setScrollPosition ] = useState(0)

  useEffect(() => {
    /*window.addEventListener("scroll", (event) => {
      let scroll = window.scrollY;
      //console.log(scroll)
      const TRESHOLD = 5
      if (((scroll+TRESHOLD) > scrollPosition) || ((scroll-TRESHOLD) < scrollPosition) ) {
        setScrollPosition(Math.floor(scroll))
      }
    });*/
    if (window.innerWidth > 600) {
    window.addEventListener('scroll', throttle(parallax, 14));

      function throttle(fn, wait) {
        var time = Date.now();
        return function() {
          if ((time + wait - Date.now()) < 0) {
            fn();
            time = Date.now();
          }
        }
      };

      function parallax() {        
        let scroll = window.pageYOffset;
        const TRESHOLD = 5
        if (((scroll+TRESHOLD) > scrollPosition) || ((scroll-TRESHOLD) < scrollPosition) ) {
          setScrollPosition(Math.floor(scroll))
        }
      };
    }
  }, [])

  return (
    <div className="App">      

      <div className="hidewhennarrow" style={{ left: 100, top: 0,  position: 'absolute', backgroundColor: 'rgba(4,4,4,0)', height: '120%', width: 370 }}>
      </div>

      <div style={{ top: 200,  position: 'absolute', backgroundColor: 'rgba(8,8,8,0)', height: 340, width: '100vw' }}>
      </div>

      <div style={{ top: 20, left: 16,  position: 'absolute' }}>     
      <img style={{ height: 40, width: 40, objectFit: 'contain', opacity: 0 }} src={require('./assets/images/more.png')} />   
        <div style={{ paddingTop: '25.4vh' }}>
          <VerticalText text={'開発者です'} />
        </div>
      </div>


      <div className="hidewhennarrow" style={{ top: 60, right: 20, position: 'absolute' }}>      
        <div style={{ paddingTop: '21.6vh' }}>
          <VerticalText text={'ソフト開発者'} />
        </div>
      </div>

      <div style={{ top: 14, left: 200, position: 'absolute' }}>
        <div style={{ paddingTop: '0vh' }}>
          <h1 style={{ fontSize: 44, color: 'rgba(95,165,225,1)' }}>{ 'ソフト' }</h1>
        </div>
      </div>

      <div className="hidewhennarrow" style={{ top: '112%', left: 200, position: 'absolute' }}>
        <div style={{ paddingTop: '0vh' }}>
          <h1 style={{ fontSize: 44, color: 'rgba(95,165,225,1)' }}>{ 'モバイル' }</h1>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>      
      <div style={{ textAlign: 'left', paddingTop: 25, flexDirection: 'row', display: 'flex' }}>            
        <div className="hidewhenwide" style={{ width: 70, height: 300 }} />    
        <div style={{ marginTop: (scrollPosition / -3) }}>
          <div style={{ opacity: 1, flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingTop: 50, maxWidth: 400 }}>            
            <div className={ "block" + (mode === 'CONTACT' ? ' block-selected' : '') } onClick={() => { setMode('CONTACT') }} style={{ width: '36vw', maxWidth: 180 }}>
              <div className="block-shade">
                <img style={{ height: '36vw', width: '36vw', maxHeight: 180, maxWidth: 180, objectFit: 'cover' }} src={require('./assets/images/knight.PNG')} />
              </div>
              <div className={ 'block-text' + (mode === 'CONTACT' ? ' block-selected' : '') }>
                <div>
                  <h3 style={{ fontFamily: 'Love Ya Like A Sister' }}>Contact</h3>
                </div>
              </div>   
            </div>            
            <div className={ "block" + (mode === 'SKILLS' ? ' block-selected' : '') } onClick={() => { setMode('SKILLS') }} style={{ width: '36vw', maxWidth: 180 }}>
              <div className="block-shade">
                <img style={{ height: '36vw', width: '36vw', maxHeight: 180, maxWidth: 180, objectFit: 'cover' }} src={require('./assets/images/tidal_vortex.png')} />
              </div>
              <div className={ 'block-text' + (mode === 'SKILLS' ? ' block-selected' : '') }>
                <h3 style={{ fontFamily: 'Love Ya Like A Sister' }}>Projects</h3>
              </div>            
            </div>        
          </div>
          <div style={{ opacity: 1, flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingTop: 0, maxWidth: 400 }}>
            <div className={ "block" + (mode === 'OCCULT' ? ' block-selected' : '') } onClick={() => { setMode('OCCULT') }} style={{ width: '36vw', maxWidth: 180 }}>
              <div className="block-shade">
                <img style={{ height: '36vw', width: '36vw', maxHeight: 180, maxWidth: 180, objectFit: 'cover' }} src={require('./assets/images/wolf.jpg')} />
              </div>
              <div className={ 'block-text' + (mode === 'OCCULT' ? ' block-selected' : '') }>
                <h3 style={{ fontFamily: 'Love Ya Like A Sister' }}>Occult</h3>
              </div>   
            </div>
            <div className={ "block" + (mode === 'CARDSBELOW' ? ' block-selected' : '') } onClick={() => { setMode('CARDSBELOW') }} style={{ width: '36vw', maxWidth: 180 }}>
              <div className="block-shade">
                <img style={{ height: '36vw', width: '36vw', maxHeight: 180, maxWidth: 180, objectFit: 'cover' }} src={require('./assets/images/ink_devour.png')} />
              </div>
              <div className={ 'block-text' + (mode === 'CARDSBELOW' ? ' block-selected' : '') }>
                <h3 style={{ fontFamily: 'Love Ya Like A Sister' }}>Cards Below</h3>
              </div>            
            </div>           
          </div>
        </div>
        
      </div>

      {/* PAGE CONTENT */}
      { mode === 'OCCULT' && (
      <div className="page-content" style={{ marginTop: 75+ (scrollPosition / -1.4) }}> 
        <div style={{ marginBottom: 30 }}>
          <img style={{ height: '100vw', maxHeight: 385, width: '100vw', maxWidth: 385, objectFit: 'cover' }} src={require('./assets/images/wolf.jpg')} />
        </div>      
        <h1>Occult</h1>
        <h2 style={{ paddingTop: 0, color: 'rgba(30,190,30,1)', fontSize: 20 }}>Deckbuilding Roguelike</h2>
        <div style={{ paddingTop: 20, paddingLeft: 30, paddingRight: 30 }}>
          <p style={{ paddingTop: 5, textAlign: 'center' }}>Travel across the dangerous terrain, defeat the lords of the lands and build your army on the way.</p>    
          <h2 style={{ paddingTop: 25 }}>Coming soon!</h2>
        </div>    
        <div style={{ paddingTop: 35, opacity: 0.2 }}>
          <img style={{ width: 160, height: 60, objectFit: 'contain' }} src={require('./assets/images/android.png')} />
          <img style={{ width: 160, height: 60, objectFit: 'contain' }} src={require('./assets/images/iphone.png')} />
        </div>
      </div> )}

      { mode === 'CARDSBELOW' && (
      <div className={ "page-content"} style={{ marginTop: 75+ (scrollPosition / -1.4) }}> 
        <div style={{ marginBottom: 30 }}>
          <img style={{ height: '100vw', maxHeight: 385, width: '100vw', maxWidth: 385, objectFit: 'cover' }} src={require('./assets/images/ink_devour.png')} />
        </div>      
        <h1>Cards Below</h1>
        <h2 style={{ paddingTop: 0, color: 'rgba(30,190,30,1)', fontSize: 20 }}>Deckbuilding Card Battle</h2>
        <div style={{ paddingTop: 20, paddingLeft: 30, paddingRight: 30 }}>
          <p style={{ paddingTop: 5, textAlign: 'center' }}>Deep below the ground, lies an ancient world. Claim your place among the rulers.</p>    
          <h2 style={{ paddingTop: 25 }}>Conquer from 2023 June</h2>
        </div>    
        <div style={{ paddingTop: 35, opacity: 0.2 }}>
          <img style={{ width: 160, height: 60, objectFit: 'contain' }} src={require('./assets/images/android.png')} />
          <img style={{ width: 160, height: 60, objectFit: 'contain' }} src={require('./assets/images/iphone.png')} />
        </div>
      </div> )}

      { mode === 'CONTACT' && (
      <div className="page-content" style={{ marginTop: 75+ (scrollPosition / -1.4) }}> 
        <div style={{ marginBottom: 30 }}>
          <img style={{ height: '100vw', maxHeight: 265, width: '100vw', maxWidth: 385, objectFit: 'cover' }} src={require('./assets/images/knight.PNG')} />
        </div>      
        <h1>Contact</h1>
        <h2 style={{ paddingTop: 0, color: 'rgba(30,190,30,1)', fontSize: 20 }}>Let's build something</h2>
        <div style={{ paddingTop: 20, paddingLeft: 30, paddingRight: 30 }}>
          <p style={{ paddingTop: 5, paddingBottom: 25, textAlign: 'center' }}>Looking for projects with passion. Money is not important. Tell your ideas :)</p>    
          <a href="https://instagram.com/tomtempo?igshid=MzNINGNkZWQ4Mg==">
            <img style={{ width: 55, height: 55, objectFit: 'contain' }} src={require('./assets/images/ig.webp')} />
          </a>
          <h2 style={{ paddingTop: 15, userSelect: 'all' }}>tom.thureson@gmail.com</h2>
        </div>    
      </div> )}

      { mode === 'SKILLS' && (
      <div className="page-content" style={{ marginTop: 75+ (scrollPosition / -1.4) }}> 
        <div style={{ marginBottom: 30 }}>
          <img style={{ height: '100vw', maxHeight: 265, width: '100vw', maxWidth: 385, objectFit: 'cover' }} src={require('./assets/images/tidal_vortex.png')} />
        </div>      
        <h1>Projects</h1>
        <h2 style={{ paddingTop: 0, color: 'rgba(30,190,30,1)', fontSize: 20 }}>Explore past experience</h2>
        <div style={{ paddingTop: 20, paddingLeft: 30, paddingRight: 30 }}>
          <p style={{ paddingTop: 5, textAlign: 'center', paddingBottom: 25 }}>Linked below is my CV (Google Docs), feel free to read about past projects.</p>    
          <div>
            <a href={'https://docs.google.com/document/d/e/2PACX-1vS76UGQ9RMH2eGtcnZ26fzJBcpaoBeafMdxDC1awkxFvfJeF6Qx61jCH4aFWcpXpgLkWb7TZdNcLpCz/pub'} className="opacity-hover" style={{  }}>
              <img style={{ height: 45, width: 45, objectFit: 'contain' }} src={require('./assets/images/google-docs.png')} />
              <h2 style={{ paddingTop: 10 }}>Click here</h2>
            </a>     
          </div>     
        </div>     
      </div> )}

      </div>

    </div>
  );
}

export default App;
